/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import Input from '../UI/Input'
import Grid from '@mui/material/Grid';
import Button from '../UI/Button'
import ProgressBar from '../UI/ProgressCard/ProgressBar'
import CustomSelect from '../UI/Select/Select'
import MultiSelect from '../UI/Select/MultiSelect'
// import { setQuestionnaireData } from '../../store/actions/questionnaireActions'
import { setWPMHistogramData } from '../../store/actions/resultActions'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RESULTS } from '../../constants/RouteConst';
import api from '../../shared/apiSetup'
import Checkbox from '@mui/material/Checkbox';
import { Paper } from '@mui/material';
import { keyboardApps } from '../../shared/Lang/SelectValues';
import { getResultsData } from '../../shared/apiUtil'
import { getGermanLanguageName, getEnglishLanguageName } from '../../shared/Utility'
import styles from './Questionnaire.module.css'

const Questionnaire = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.home.isMobileDev);
    const uid = useSelector(state => state.user.uid);
    const questionPageLang = useSelector(state => state.user.questionPageLang);
    const questionFormLang = useSelector(state => state.user.questionFormLang);
    const systemLanguage = useSelector(state => state.home.systemLanguage);
    const appLang = useSelector(state => state.user.appLang);
    const continueCode = useSelector(state => state.user.continueCode);

    const [pageIndex, setPageIndex] = useState(0)
    const [selectData, setSelectData] = useState([])
    const [mobHabitFingerList, setMobHabitFingerList] = useState([])
    const [mobHandList, setMobHandList] = useState([])
    const [keyBoardFeaturesList, setKeyboardFeaturesList] = useState([])
    const [qFormLang, setqFormLang] = useState(questionFormLang)
    // const [btnDisable, setBtnDisable] = useState(false)
    const [formVals, setFormVals] = useState({
        uid: uid,
        age: null,
        gender: null,
        nativeLanguages: (appLang === 'English') ? getEnglishLanguageName() : getGermanLanguageName(),
        typeTestLanguage: null,
        keyboardType: null,
        keyboardTypeDetails: null,
        keyboardLayout: null,
        keyboardLayoutDetails: null,
        keyboardTypingTime: null,
        typingHabitFingers: null,
        typingHabitHands: null,
        typingSpeed: null,
        typingAccuracy: null,
        keyboardApp: null,
        keyboardAppYes: null,
        keyboardAppDetails: null,
        keyboardFeatures: null,
        keyboardFeaturesDetails: null,
        keyboardFeaturesWhy: null,
        keyboardFeaturesWhyDetails: null
    })

    const [selectList, setSelectList] = useState(['age', 'gender', 'nativeLanguages', 'typeTestLanguage', 'keyboardType', 'typingHabitHands', 'typingHabitFingers',
        'keyboardLayout', 'keyboardTypingTime', 'typingSpeed', 'typingAccuracy', 'keyboardApp', 'keyboardFeatures', 'keyboardFeaturesWhy'])


    useEffect(() => {
        console.log(questionFormLang)
        setqFormLang(questionFormLang)
    }, [questionFormLang])

    const btnDataNext = {
        btnName: questionPageLang.nextButton,
        // width: 200
    }

    const btnDataBack = {
        btnName: questionPageLang.backButton,
        // width: 200
    }

    const submitQuestionnaireData = async () => {
        // const formData = [userPageVals, keyBoardPageVals, typingPageVals]
        try {
            const response = await api.post('/submitQuestionnaireData', formVals);
            // if (response.data.id) {
            //     console.log(response.data.id, "newly added test section id")
            //     setTsId(response.data.id)
            // }
            if (response.data) {
                return true
            }
            // console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleNext = async () => {
        const maxIndex = isMobile ? 3 : 2
        if ((pageIndex === 2 && isMobile === false) || pageIndex === 3) {
            const result = submitQuestionnaireData()
            result.then(() => {
                // The promise has resolved
                // const resultData = getResultsData(uid)
                getResultsData(uid)
                    .then(resultData => {
                        // Handle the result object here
                        dispatch(setWPMHistogramData(resultData))
                        navigate(RESULTS);
                    })
                    .catch(error => {
                        // Handle any errors here
                        console.error(error);
                    });
            });
        }

        if (pageIndex < maxIndex) {
            setPageIndex(pageIndex + 1)
        }
    }

    const handleBack = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    useEffect(() => {
        switch (pageIndex) {
            case 0:
                setSelectData(qFormLang.userData)
                break;
            case 1:
                setSelectData(qFormLang.keyboardData)
                break;
            case 2:
                const typData = isMobile ? qFormLang.typingDataMobile : qFormLang.typingData
                let tempFormVals = JSON.parse(JSON.stringify(formVals));
                if (tempFormVals.keyboardTypingTime === '>13') {
                    tempFormVals.keyboardTypingTime = 13
                }
                setFormVals(tempFormVals)
                setSelectData(typData)
                break;
            case 3:
                setSelectData(qFormLang.mobileKeyboardData)
                break;
            default:
                break;
        }
    }, [pageIndex])

    const getProgressVal = () => {
        let ind;
        if (isMobile) {
            ind = (pageIndex === 0) ? 25 :
                (pageIndex === 1) ? 50 :
                    (pageIndex === 2) ? 75 :
                        (pageIndex === 3) ? 100 : 0
        } else {
            ind = (pageIndex === 0) ? 25 :
                (pageIndex === 1) ? 50 :
                    (pageIndex === 2) ? 100 : 0
        }
        return ind
    }

    const onSelectChange = (e, label) => {
        let tempFormVals = JSON.parse(JSON.stringify(formVals));
        tempFormVals[label] = e.target.value
        setFormVals(tempFormVals)
    }

    const checkBtnDisable = () => {
        let isbtnDisable = true
        if (pageIndex === 0) {
            const labels = qFormLang.userData.map(item => item.label);
            labels.forEach(eachLabel => {
                if (formVals[eachLabel] === null) {
                    isbtnDisable = true
                } else {
                    isbtnDisable = false
                }
            });
            // (eachLabel => formVals[eachLabel])
            // isbtnDisable = (formVals.age === null ||
            //     formVals.gender === null ||
            //     formVals.nativeLanguages === null ||
            //     formVals.typeTestLanguage === null) ? true : false
        } else if (pageIndex === 1) {
            const labels = qFormLang.keyboardData.map(item => item.label);
            labels.forEach(eachLabel => {
                if (formVals[eachLabel] === null && selectData.includes(eachLabel)) {
                    isbtnDisable = true
                } else {
                    isbtnDisable = false
                }
            });
            // isbtnDisable = (formVals.keyboardType === null ||
            //     formVals.keyboardLayout === null ||
            //     formVals.keyboardTypingTime === null) ? true : false
        } else if (pageIndex === 2) {
            isbtnDisable = (formVals.typingHabitFingers === null ||
                formVals.typingSpeed === null ||
                formVals.typingAccuracy === null || (isMobile && formVals.typingHabitHands === null)) ? true : false
        } else if (pageIndex === 3) {
            isbtnDisable = (formVals.keyboardApp === null ||
                formVals.keyboardFeatures === null ||
                formVals.keyboardFeaturesWhy === null) ? true : false
        }
        // console.log(isbtnDisable, pageIndex)
        return isbtnDisable
    }

    // console.log(selectData, "selectData")
    const setTypeFingerVals = (typeFingerVals) => {
        let tempFormVals = JSON.parse(JSON.stringify(formVals));
        tempFormVals.typingHabitFingers = typeFingerVals.join(', ')
        setFormVals(tempFormVals)
    }

    const setTypingHandsvals = (typeHandVals) => {
        let tempFormVals = JSON.parse(JSON.stringify(formVals));
        tempFormVals.typingHabitHands = typeHandVals.join(', ')
        setFormVals(tempFormVals)
    }

    const setKeyboardFeatureList = (typeFingerVals) => {
        let tempFormVals = JSON.parse(JSON.stringify(formVals));
        tempFormVals.keyboardFeatures = typeFingerVals.join(', ')
        setFormVals(tempFormVals)
    }

    const onCheckboxChange = (label, event) => {

        if (label === 'keyboardFeatures') {
            const tempselectedList = [...keyBoardFeaturesList]
            if (tempselectedList.includes(event.target.name) === false) {
                tempselectedList.push(event.target.name)
                setKeyboardFeaturesList(tempselectedList)
                setKeyboardFeatureList(tempselectedList)
                // updateSelectData(tempselectedList)
            } else {
                let filterList = tempselectedList.filter(item => item !== event.target.name)
                setKeyboardFeaturesList(filterList)
                if (filterList.length === 0) {
                    filterList = null
                } else {
                    setKeyboardFeaturesList(filterList)
                }
                console.log(filterList)
            }
        } else {
            const tempselectedList = [...mobHabitFingerList]
            if (tempselectedList.includes(event.target.name) === false) {
                tempselectedList.push(event.target.name)
                setMobHabitFingerList(tempselectedList)
                setTypeFingerVals(tempselectedList)
                // updateSelectData(tempselectedList)
            } else {
                let filterList = tempselectedList.filter(item => item !== event.target.name)
                setMobHabitFingerList(filterList)
                if (filterList.length === 0) {
                    filterList = null
                } else {
                    setTypeFingerVals(filterList)
                }
            }
        }
    };

    const onHabitHandsChange = (event) => {
        const tempHandList = [...mobHandList]
        if (tempHandList.includes(event.target.name) === false) {
            tempHandList.push(event.target.name)
            setMobHandList(tempHandList)
            setTypingHandsvals(tempHandList)
            // updateSelectData(tempselectedList)
        } else {
            let filterList = tempHandList.filter(item => item !== event.target.name)
            setMobHandList(filterList)
            if (filterList.length === 0) {
                filterList = null
            } else {
                setTypingHandsvals(filterList)
            }
        }
    }

    const onInputChange = (e, label) => {
        let tempFormVals = JSON.parse(JSON.stringify(formVals));
        const labl = (label === 'keyboardType') ? 'keyboardTypeDetails' :
            (label === 'keyboardApp') ? 'keyboardAppDetails' :
                (label === 'keyboardFeatures') ? 'keyboardFeaturesDetails' :
                    (label === 'keyboardFeaturesWhy') ? 'keyboardFeaturesWhyDetails' :
                        'keyboardLayoutDetails'
        tempFormVals[labl] = e.target.value
        setFormVals(tempFormVals)
    }

    const handleLabel = (labelVal) => {
        if (['Mobile phone', 'Tablet', 'Laptop', 'Desktop'].includes(formVals.keyboardType)) {
            return labelVal.replace(/your keyboard/, `your ${formVals.keyboardType.toLowerCase()} keyboard`);
        } else if (formVals.keyboardType === 'Add-on physical keyboard for mobile devices') {
            labelVal.replace(' keyboard', '');
            return labelVal.replace(/your keyboard/, `your ${formVals.keyboardType.toLowerCase()} keyboard`);
        } else {
            return labelVal
        }
    }

    // const setDefaultLang = () => {
    //     if (appLang === 'English') {
    //         const name = getEnglishLanguageName()
    //         console.log("name", name)
    //         return name
    //     } else if (appLang === "Deutsch") {
    //         const name = getGermanLanguageName()
    //         console.log("name", name)
    //         return name
    //     }
    // }

    return (
        <div className={styles.questContainer}>
            <ProgressBar
                heading={questionPageLang.heading}
                maxVal={isMobile ? 4 : 3}
                curVal={pageIndex + 1}
                val={getProgressVal()}
                isMob={isMobile}
            />
            <div className={styles.questInfo}>{qFormLang.info}</div>
            <div className={styles.questGridContainer}>
                <div>
                    <Grid container spacing={6}>
                        {
                            selectData.map((each, index) => (
                                <Grid item xs={isMobile ? 12 : 6} sx={{ paddingTop: isMobile ? '15px !important' : 'inherit' }} key={each.label}>
                                    {
                                        (selectList.includes(each.label)) &&
                                        (
                                            <div className={styles.selectLabel}>
                                                {
                                                    (each.label === 'keyboardTypingTime'
                                                        && formVals.keyboardType && formVals.keyboardType !== 'Other')
                                                        ? handleLabel(each.val)
                                                        : each.val
                                                }
                                            </div>
                                        )
                                    }
                                    {

                                        (each.label === 'typingHabitFingers' && !isMobile)
                                            ? <MultiSelect multiList={each.selectData} setTypeFingerVals={(typeFingerVals) => setTypeFingerVals(typeFingerVals)} />
                                            : (isMobile && each.label === 'typingHabitFingers')
                                                ? (<div>
                                                    {
                                                        each.selectData.map((eachListData, index) => {
                                                            return (
                                                                <Paper sx={{ background: '#E5E4E2', marginBottom: 1 }}>
                                                                    <div className={styles.checkboxSelectWrap}>
                                                                        <Checkbox
                                                                            checked={mobHabitFingerList.includes(eachListData.name)}
                                                                            onChange={(e) => onCheckboxChange(each.label, e)}
                                                                            name={eachListData.name}
                                                                            label={eachListData.name}
                                                                        />
                                                                        <div> {eachListData.name} </div>
                                                                        <div> {(eachListData.src !== '') && <img src={eachListData.src} alt="img" width={56} />}</div>
                                                                    </div>
                                                                </Paper>
                                                            )
                                                        }
                                                        )
                                                    }
                                                </div>)
                                                : (isMobile && each.label === 'keyboardFeatures')
                                                    ? (<div>
                                                        {
                                                            each.selectData.map((eachListData, index) => {
                                                                return (
                                                                    <Paper sx={{ background: '#E5E4E2', marginBottom: 1 }}>
                                                                        {(
                                                                            eachListData.name === 'Swipe'
                                                                            || eachListData.name === 'Prediction'
                                                                            || eachListData.name === 'Autocorrection'
                                                                        )
                                                                            ? (
                                                                                <div className={styles.keyboardFeaturesWrap}>
                                                                                    <div className={styles.keyboardFeatureName}> {eachListData.name} </div>
                                                                                    <div className={styles.keyboardFeatureInfo}>{eachListData.info}</div>
                                                                                    <div className={styles.checkboxWrap}>
                                                                                        <Checkbox
                                                                                            checked={keyBoardFeaturesList.includes(eachListData.name)}
                                                                                            onChange={(e) => onCheckboxChange(each.label, e)}
                                                                                            name={eachListData.name}
                                                                                            label={eachListData.name}
                                                                                        />
                                                                                        {
                                                                                            <img src={eachListData.img} alt={eachListData.name} width={100} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : (
                                                                                <div className={styles.checkboxSelectWrap}>
                                                                                    <Checkbox
                                                                                        checked={keyBoardFeaturesList.includes(eachListData.name)}
                                                                                        onChange={(e) => onCheckboxChange(each.label, e)}
                                                                                        name={eachListData.name}
                                                                                        label={eachListData.name}
                                                                                    />
                                                                                    {
                                                                                        <div className={styles.keyboardFeatureName}> {eachListData.name} </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Paper>
                                                                )
                                                            }
                                                            )
                                                        }
                                                        {
                                                            (each.label === 'keyboardFeatures' && keyBoardFeaturesList.includes('Other'))
                                                            && (
                                                                <Input
                                                                    value={
                                                                        formVals['keyboardFeaturesDetails']
                                                                    }
                                                                    onInputChange={(e) => onInputChange(e, each.label)}
                                                                    fullwidth={true}
                                                                />
                                                            )
                                                        }
                                                    </div>)
                                                    : (isMobile && (each.label === 'typingHabitHands')
                                                        ? (
                                                            <div>
                                                                {
                                                                    each.selectData.map((eachListData, index) => {
                                                                        return (
                                                                            <Paper sx={{ background: '#E5E4E2', marginBottom: 1 }}>
                                                                                <div className={styles.checkboxSelectWrap}>
                                                                                    <Checkbox
                                                                                        checked={mobHandList.includes(eachListData)}
                                                                                        onChange={onHabitHandsChange}
                                                                                        name={eachListData}
                                                                                        label={eachListData}
                                                                                    />
                                                                                    <div> {eachListData} </div>
                                                                                </div>
                                                                            </Paper>
                                                                        )
                                                                    }
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                        : (selectList.includes(each.label))
                                                            ? (
                                                                <>
                                                                    <CustomSelect
                                                                        value={formVals[each.label]}
                                                                        listValues={each.selectData}
                                                                        defaultVal={each.defaultVal}
                                                                        onChange={(e) => onSelectChange(e, each.label)}
                                                                    />
                                                                    {((each.label === 'keyboardType' && formVals.keyboardType === 'Other')
                                                                        || (each.label === 'keyboardLayout' && formVals.keyboardLayout === 'Other')
                                                                        || (each.label === 'keyboardFeaturesWhy' && formVals.keyboardFeaturesWhy === 'Other')
                                                                    )
                                                                        && (
                                                                            <div style={{ marginTop: 30 }}>
                                                                                <div>
                                                                                    {
                                                                                        (each.label === 'keyboardType')
                                                                                            ? 'Keyboard Type Details'
                                                                                            : (each.label === 'keyboardLayout')
                                                                                                ? 'Keyboard Layout Details'
                                                                                                : (each.label === 'keyboardFeaturesWhy')
                                                                                                    ? 'Key board feature why data'
                                                                                                    : ''
                                                                                    }
                                                                                </div>
                                                                                <Input
                                                                                    value={
                                                                                        (each.label === 'keyboardType')
                                                                                            ? formVals['keyboardTypeDetails']
                                                                                            : (each.label === 'keyboardLayout')
                                                                                                ? formVals['keyboardLayoutDetails']
                                                                                                : (each.label === 'keyboardFeaturesWhy')
                                                                                                    ? formVals['keyboardFeaturesWhyDetails']
                                                                                                    : ''
                                                                                    }
                                                                                    onInputChange={(e) => onInputChange(e, each.label)}
                                                                                    fullwidth={true}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {(each.label === 'keyboardApp' && formVals.keyboardApp === 'Yes')
                                                                        &&
                                                                        (
                                                                            <>
                                                                                <CustomSelect
                                                                                    value={formVals['keyboardAppYes']}
                                                                                    listValues={keyboardApps}
                                                                                    defaultVal={'Select App'}
                                                                                    onChange={(e) => onSelectChange(e, 'keyboardAppYes')}
                                                                                />
                                                                                {
                                                                                    (formVals['keyboardAppYes'] === 'Other') &&
                                                                                    <Input
                                                                                        value={formVals['keyboardAppDetails']}
                                                                                        onInputChange={(e) => onInputChange(e, each.label)}
                                                                                        fullwidth={true}
                                                                                    />
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                            : ''
                                                    )}
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </div>
            <div className={styles.questionBtnsWrap}>
                {
                    (pageIndex !== 0) &&
                    (
                        <div className={styles.buttonLeft}>
                            <Button btnData={btnDataBack} handleBtnClick={() => handleBack()} />
                        </div>
                    )
                }
                <div className={styles.buttonRight}>
                    <Button btnData={btnDataNext} handleBtnClick={() => handleNext()} checkIsDisable={checkBtnDisable()} />
                </div>
            </div>
        </div>
    )
}

export default Questionnaire
