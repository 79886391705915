import { useState, useEffect } from "react";
import LineChart from "./LineChart";
import { setIsMobile } from "../../store/actions/homeActions";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

function App({ allWPM, isMobile }) {

  const [lineData, setLineData] = useState({
    datasets: [
      {
        label: "",
        data: allWPM.map((value, index) => [index + 1, parseInt(value)]),
        borderColor: "#50AF95",
        borderWidth: 2,
        fill: false, // Set to false to display only the line without filling the area underneath
        legend: {
          display: false,
        },
      },

    ],
  })

  const lineOptions = {
    scales: {
      x: {
        type: "linear", // Use a categorical axis for Sentence IDs
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          min: 1,
          max: allWPM.length, // Adjust this value based on the number of sentences
          maxTicksLimit: allWPM.length,
        },
        title: {
          display: true,
          text: 'Sentence'
        },
      },
      y: {
        type: "linear",
        position: "left",
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 10,
          min: 0,
          max: 100, // Adjust this value based on your WPM data range
        },
        title: {
          display: true,
          text: 'WPM'
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    }
  };

  return (
    <div>
      <div style={{ width: '100%', margin: isMobile ? 0 : "70px 0px" }}>
        <LineChart chartData={lineData} options={lineOptions} />
      </div>
    </div>
  );
}

export default App;