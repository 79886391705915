import {
    nativeLanguageOptions, nativeLanguageOptions_de,
    typeTestLangList, typeTestLangList_de,
    genderList, genderList_de,
    keyboardTypeList, keyboardTypeList_de, keyboardLayoutList, keyboardLayoutList_de,
    typeFingerList, typeFingerList_de,
    keyboardAppList, keyboardAppList_de, keyboardFeaturesWhyList, keyboardFeaturesWhyList_de,
    keyboardFeaturesList, keyboardFeaturesList_de,
    ageList, keyboardTypingTimeList, typingSpeedList, typingAccuracyList, 
    typeFingerListMobile, typingHandsList, typingHandsImgs, keyboardApps
} from './SelectValues'


export const formTextLables_en = {
    info: "Please answer the questionnaire. Your results will be shown afterwards.",
    userData: [
        {
            label: "age",
            val: 'What is your age?',
            selectData: ageList
        },
        {
            label: "gender",
            val: 'What is your gender?',
            selectData: genderList
        },
        {
            label: "nativeLanguages",
            val: 'What is your native language?',
            defaultVal: 'Select language',
            selectData: nativeLanguageOptions
        },
        {
            label: "typeTestLanguage",
            val: 'How often do you type in English?',
            selectData: typeTestLangList
        }
    ],
    keyboardData: [
        {
            label: "keyboardType",
            val: 'What kind of keyboard are you using currently?',
            selectData: keyboardTypeList
        },
        {
            label: "keyboardTypeDetails", // show input field to enter the other type
            // val: "Enter your keyboard type here" 
        },
        {
            label: "keyboardLayout",
            val: 'Which keyboard layout do you use?',
            info: 'If you are not sure, tap the input field and check your keyboard. Which are the first six letters in the uppermost row?',
            selectData: keyboardLayoutList
        },
        {
            label: "keyboardLayoutDetails"
        },
        {
            label: "keyboardTypingTime",
            val: 'On average, how many hours per day do you spend writing with your keyboard?',
            // val: 'On average, how many hours per day do you spend writing with your <b><span id="device-disclaimer">mobile</span></b> keyboard?',
            defaultVal: 'Select amount',
            selectData: keyboardTypingTimeList
        }
    ],
    typingData: [
        {
            label: "typingHabitFingers",
            val: 'What do you think, which fingers did you use for typing during the test?',
            selectData: typeFingerList
        },
        {
            info: '1 (very slow) 7 (very fast)',
            label: "typingSpeed",
            val: 'How do you feel about your typing speed on a scale of 1 (very slow) to 7 (very fast)?',
            defaultVal: 'Select value',
            selectData: typingSpeedList
        },
        {
            info: '1(a lot of mistakes), 7(no mistakes at all)',
            label: "typingAccuracy",
            val: 'How do you feel about your typing accuracy on a scale of 1(a lot of mistakes) to 7(no mistakes at all) ?',
            defaultVal: 'Select value',
            selectData: typingAccuracyList
        }
    ],
    typingDataMobile: [
        {
            label: "typingHabitHands",
            val: 'Which hand did you use to type during the test?',
            selectData: typingHandsList,
        },
        {
            label: "typingHabitFingers",
            val: 'Which fingers did you use to type during the test?',
            selectData: typeFingerListMobile,
        },
        {
            info: '1 (very slow) 7 (very fast)',
            label: "typingSpeed",
            val: 'How do you feel about your typing speed on a scale of 1 (very slow) to 7 (very fast)?',
            defaultVal: 'Select value',
            selectData: typingSpeedList
        },
        {
            info: '1(a lot of mistakes), 7(no mistakes at all)',
            label: "typingAccuracy",
            val: 'How do you feel about your typing accuracy on a scale of 1(a lot of mistakes) to 7(no mistakes at all) ?',
            defaultVal: 'Select value',
            selectData: typingAccuracyList
        }
    ],
    mobileKeyboardData: [
        {
            label: "keyboardApp",
            val: 'Do you use a keyboard, installed from the app store?',
            selectData: keyboardAppList
        },
        {
            label: "keyboardAppYes",
            val: '',
            selectData: keyboardApps
        },
        {
            label: "keyboardAppYesDetails",
            val: ''
        },
        {
            label: "keyboardFeatures",
            val: 'Have you used any advanced features during the typing test?',
            selectData: keyboardFeaturesList
        },
        {
            label: "keyboardFeaturesDetails",
            val: ''
        },
        {
            label: "keyboardFeaturesWhy",
            val: 'Why do you use prediction or autocorrection?',
            selectData: keyboardFeaturesWhyList
        },
        {
            label: "keyboardFeaturesWhyDetails",
            val: ''
        }
    ],
    btnData: {
        backBtn: "Back",
        nextBtn: "Next"
    },
}

export const formTextLables_de = {
    info: "Bitte beantworten Sie zuerst die folgenden Fragen. Ihre Ergebnisse werden anschließend angezeigt.",
    userData: [
        {
            label: "age",
            val: 'Wie alt sind Sie?',
            selectData: ageList
        },
        {
            label: "gender",
            val: 'Geschlecht?',
            selectData: genderList_de
        },
        {
            label: "nativeLanguages",
            val: 'Was ist Ihre Muttersprache?',
            defaultVal: 'Sprache auswählen',
            selectData: nativeLanguageOptions_de
        },
        {
            label: "typeTestLanguage",
            val: 'Wie oft tippen Sie auf Deutsch?',
            selectData: typeTestLangList_de
        }
    ],
    keyboardData: [
        {
            label: "keyboardType",
            val: 'Welche Art von Tastatur benutzen Sie gerade?',
            selectData: keyboardTypeList_de
        },
        {
            label: "keyboardTypeDetails", // show input field to enter the other type
            // val: "Enter your keyboard type here" 
        },
        {
            label: "keyboardLayout",
            val: 'Welches Tastaturlayout verwenden Sie?',
            info: 'Wenn Sie sich nicht sicher sind, tippen Sie auf das Eingabefeld und überprüfen Sie Ihre Tastatur. Was sind die ersten sechs Buchstaben in der obersten Reihe?',
            selectData: keyboardLayoutList_de
        },
        {
            label: "keyboardLayoutDetails"
        },
        {
            label: "keyboardTypingTime",
            val: 'Wie viele Stunden pro Tag verbringen Sie im Durchschnitt mit der Tastatur Ihres Mobilgeräts?',
            // val: 'On average, how many hours per day do you spend writing with your <b><span id="device-disclaimer">mobile</span></b> keyboard?',
            defaultVal: 'Anzahl auswählen',
            selectData: keyboardTypingTimeList
        }
    ],
    typingData: [
        {
            label: "typingHabitFingers",
            val: 'Welche Finger glauben Sie haben Sie zum Tippen während des Tests genutzt? ',
            selectData: typeFingerList_de
        },
        {
            info: '1 (sehr langsam) 7 (sehr schnell)',
            label: "typingSpeed",
            val: 'Wie empfinden Sie Ihre Tippgeschwindigkeit auf einer Skala von 1 (sehr langsam) bis 7 (sehr schnell)?',
            defaultVal: 'Wert auswählen',
            selectData: typingSpeedList
        },
        {
            info: '1 (sehr viele Fehler), 7 (überhaupt keine Fehler)',
            label: "typingAccuracy",
            val: 'Wie empfinden Sie Ihre Genauigkeit beim Tippen auf einer Skala von 1 (sehr viele Fehler) bis 7 (überhaupt keine Fehler)?',
            defaultVal: 'Wert auswählen',
            selectData: typingAccuracyList
        }
    ],
    mobileKeyboardData: [
        {
            label: "keyboardApp",
            val: 'Benutzen Sie eine Tastatur, die aus dem App Store installiert wurde?',
            selectData: keyboardAppList_de
        },
        {
            label: "keyboardAppYes",
            val: ''
        },
        {
            label: "keyboardAppYesDetails",
            val: ''
        },
        {
            label: "keyboardFeatures",
            val: 'Haben Sie während des Tipptests besondere Funktionen verwendet?',
            selectData: keyboardFeaturesList_de
        },
        {
            label: "keyboardFeaturesDetails",
            val: ''
        },
        {
            label: "keyboardFeaturesWhy",
            val: 'Wieso benutzen Sie Wortvorhersage oder Autokorrektur?',
            selectData: keyboardFeaturesWhyList_de
        },
        {
            label: "keyboardFeaturesWhyDetails",
            val: ''
        }
    ],
    btnData: {
        backBtn: "Zurück",
        nextBtn: "Weiter"
    },
}


export const questPageData_en = {
    heading: "Questionnaire",
    info: "Please answer the questionnaire. Your results will be shown afterwards.",
    nextButton: "Next",
    backButton: "Back"
}

export const questPageData_de = {
    heading: "Fragebogen",
    info: "Bitte beantworten Sie zuerst die folgenden Fragen. Ihre Ergebnisse werden anschließend angezeigt.",
    nextButton: "Weiter",
    backButton: "Zurück"
}


// views.questionnaire.texts.keyboardapp.yes.default=App auswählen
// views.questionnaire.texts.keyboardapp.yes.other=Sonstiges

// views.questionnaire.headings.keyboardfeatures=Haben Sie während des Tipptests besondere Funktionen verwendet?
// views.questionnaire.texts.keyboardfeatures.swipe.description=Um ein Wort einzugeben, fahren Sie von einem Buchstaben zum anderen, ohne den Finger vom Bildschirm zu heben.
// views.questionnaire.texts.keyboardfeatures.prediction.description=Die Tastatur macht Vorschläge für das Wort, das Sie gerade eingeben oder als nächstes eingeben werden. Sie wählen das passende Wort manuell aus, d.h. der eingegeben Text wird nicht automatisch geändert.
// views.questionnaire.texts.keyboardfeatures.autocorrection.description=Nach dem Drücken der Leertaste werden Tippfehler automatisch korrigiert oder das Wort vervollständigt.
// views.questionnaire.headings.keyboardfeatureswhy=Wieso benutzen Sie Wortvorhersage oder Autokorrektur?

// views.questionnaire.buttons.back=Zurück
// views.questionnaire.buttons.next=Weiter
// views.questionnaire.buttons.results=Ergebnis
// views.questionnaire.filepaths.js.native_language_options=javascripts/native_language_options_de.js
