import React from 'react'
import { Select, MenuItem } from '@mui/material';

const CustomSelect = ({ value, onChange, listValues, fullwidth, defaultVal }) => {
    return (
        <div
        //  style={{ width: '100%' }}
        >
            <Select
                value={value}
                onChange={onChange}
                sx={{ width: '100%' }}
                defaultValue={defaultVal}
            >
                {listValues.map((each) => (
                    <MenuItem key={each} value={each}>
                        {each}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

export default CustomSelect
