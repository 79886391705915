import React from 'react'
import Divider from '@mui/material/Divider';
import resultClass from './Results.module.css'

const ResultHighlights = ({ restultPageLang, histogramData, isMobile }) => {
    const resHighData = [
        {
            heading: restultPageLang.maxwpm.heading,
            errors: restultPageLang.maxwpm.errors,
            errVal: histogramData.maxWPM[1],
            wpm: restultPageLang.maxwpm.wpm,
            wpmVal: parseInt(histogramData.maxWPM[2]),
            sentence: histogramData.maxWPM[0]
        },
        {
            heading: restultPageLang.minWpm.heading,
            errors: restultPageLang.minWpm.errors,
            errVal: histogramData.minWPM[1],
            wpm: restultPageLang.minWpm.wpm,
            wpmVal: parseInt(histogramData.minWPM[2]),
            sentence: histogramData.minWPM[0]
        },
        {
            heading: restultPageLang.maxerrorrate.heading,
            errors: restultPageLang.maxerrorrate.errors,
            errVal: histogramData.maxErrRate[2],
            wpm: restultPageLang.maxerrorrate.wpm,
            wpmVal: parseInt(histogramData.maxErrRate[3]),
            sentence: histogramData.maxErrRate[0],
            correctSentence: histogramData.maxErrRate[1]
        }
    ]

    return (
        <div className={resultClass.resHighContainer}>
            {resHighData.map((each, index) => (
                <>
                    <div className={resultClass.resHighEleWrap}>
                        <div className={resultClass.resHighEleHeading}>{each.heading}</div>
                        <div className={resultClass.errWpmWrap}>
                            <div className={resultClass.errWpmVal}>
                                {each.errors}: <span style={{ color: 'black' }}>{each.errVal}</span>
                            </div>
                            <div className={resultClass.errWpmVal} style={{ marginLeft: 20 }}>
                                {each.wpm}: <span style={{ color: 'black' }}>{each.wpmVal}</span>
                            </div>
                        </div>
                    </div>
                    <div className={resultClass.resHighSentence}>{each.sentence}</div>
                    {
                        (index === 2) && <div className={resultClass.resHighSentence}>({histogramData.maxErrRate[1]})</div>
                    }
                    {(index < 2) && <Divider variant="middle" sx={{ marginTop: 3, marginBottom: 2 }} />}
                </>
            ))}
        </div>
    )
}

export default ResultHighlights