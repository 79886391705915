import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
import { useSelector } from 'react-redux';

function ResponsiveAppBar() {
    const headerLang = useSelector(state => state.user.headerLang);

    const [headerData, setHeaderData] = useState(headerLang)

    useEffect(() => {
        setHeaderData(headerLang)
    }, [headerLang])

    return (
        <AppBar
            position="static"
            sx={headerContainer}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={headerContent}
                    >
                        {headerData.name}
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
}


const headerContainer = {
    backgroundColor: '#008080',
}

const headerContent = {
    mr: 1,
    display: { md: 'flex' },
    fontWeight: 600,
    letterSpacing: '.01rem',
    color: 'inherit',
    textDecoration: 'none',
}

export default ResponsiveAppBar;
