import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/Utility';
import initialState from './initialState';

export const setQuestionnaireData = (state, action) => {
  return (
    updateObject(state, {
        formData: action.data,
    })
  )
};

const homeReducer = (state = initialState.questionnaire, action) => {
  switch (action.type) {
    case actionTypes.SET_QUESTIONNAIRE_DATA: return setQuestionnaireData(state, action);
    default:
      return state;
  }
};

export default homeReducer;
