import React from 'react';
import { Route, Routes } from 'react-router-dom';
import * as ROUTES from '../../constants/RouteConst';
import Home from '../Home/Home';
import Test from '../Test/Test';
import Questionnaire from '../Questionnaire/Questionnaire'
import Results from '../Results/Results';

const AppRouter = () => {

  return (
      <Routes>
        <Route exact path={ROUTES.HOME} element={<Home/>} />
        <Route exact path ={ROUTES.TEST} element={<Test/>} />
        <Route exact path ={ROUTES.QUESTION} element={<Questionnaire/>} />
        <Route exact path ={ROUTES.RESULTS} element={<Results/>} />
      </Routes>
  );
};

export default AppRouter;
