import * as React from 'react';
import Button from '@mui/material/Button';

const BasicButton = ({ btnData, handleBtnClick, checkIsDisable }) => {
    return (
        <Button
            variant="contained"
            onClick={handleBtnClick}
            sx={{
                background: '#FFA500',
                width: btnData.width,
                fontWeight: "bold",
                color: 'black',
                height: btnData.height || 'inherit',
                '&:hover': {
                    backgroundColor: '#FFA500', // Change this to your desired hover color
                },
            }}
            disabled={checkIsDisable}
        >
            {btnData.btnName}
        </Button>
    );
}

export default BasicButton
