import * as actionTypes from './actionTypes';

export const setIsMobile = (data) => ({
  type: actionTypes.SET_IS_MOBILE,
  data
});

export const setSystemLanguage = (data) => ({
  type: actionTypes.SET_SYSTEM_LANGUAGE,
  data
});
