import * as actionTypes from './actionTypes';

export const setUserData = (data) => ({
    type: actionTypes.SET_USER_DATA,
    data
  });

  export const setAppLang = (data) => ({
    type: actionTypes.SET_APP_LANG,
    data
  });

  export const  setExistingCode = (data) => ({
    type: actionTypes.SET_EXISTING_CODE,
    data
  });

 
