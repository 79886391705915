import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TEST } from '../../constants/RouteConst';
import { contactDetails } from '../../shared/Lang/contactUs';
import { setSentenceList } from '../../store/actions/testActions';
import { Paper } from '@mui/material';
import { copyToClipImg } from '../UI/Icons';
import FAQ from './FAQ'
import LineGraph from './LineGraph'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Histogram from './Histogram';
import Button from '../UI/Button'
import api from '../../shared/apiSetup'
import Notification from '../UI/Snackbar'
import resultClass from './Results.module.css'
import ResultBar from './ResultBar';
import ResultHighlights from './ResultHighlights'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const WpmHistogramChart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [chartData, setChartData] = useState([]);
  const [bucketIndex, setBucketIndex] = useState(null);
  const [email, setInputVal] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [isNotif, setNotif] = useState(false);
  const [notifMsg, setNotifMsg] = useState('');

  const histogramData = useSelector(state => state.results.wpmHistogramData);
  const continueCode = useSelector(state => state.user.continueCode);
  const isExistingCode = useSelector(state => state.user.isExistingCode);
  const restultPageLang = useSelector(state => state.user.restultPageLang);
  const isMobile = useSelector(state => state.home.isMobileDev);
  const lang = useSelector(state => state.user.appLang);
  const uid = useSelector(state => state.user.uid);

  useEffect(() => {
    console.log(histogramData, "histogramdata")
    // setChartData(histogramData)
  }, [histogramData])


  const subHeading = {
    fontWeight: 500
  }

  const btnData = {
    btnName: "Continue",
    width: '100%',
    height: 50
  }

  const sendEmailBtn = {
    btnName: "Send",
    textAlign: 'center',
    width: '100%',
    height: 50
  }


  const onSendContCode = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('uid', uid)
    formData.append('url', window.location.hostname)

    try {
      const response = await api.post('/sendContinueCode', formData);
      if (response.status === 200) {
        setNotif(true)
        setNotifMsg(response.data.message)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const onContinueTest = async () => {
    const appLang = {
      lang: lang
    }
    try {
      const response = await api.post('/getSentences', appLang);
      dispatch(setSentenceList(response.data.sentenceList))
      navigate(TEST);
    } catch (error) {
      console.error('Error fetching string array:', error);
    }
    navigate(TEST);
  }
  const onInputChange = (e) => {
    setInputVal(e.target.value)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess("Copied to clipboard!");
        setTimeout(function () {
          setCopySuccess("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className={resultClass.resultsContainer}>
      {
        isNotif && <Notification isOpen={true} msg={notifMsg} notifType="success" isAutoHide={true} onNotifClose={() => setNotif(false)} />
      }
      <ResultBar
        device={isMobile ? 'Mobile' : 'Desktop'}
        lang={lang}
        wpm={parseInt(histogramData.avgWPM)}
        backspace={histogramData.backspaceData.avg_backspace.toFixed(2)}
        errRate={parseInt(histogramData.avgErrRate)}
      />

      <div className={resultClass.continueCodeWrap}>
        <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', marginTop: 2 }}>
          {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
          <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 10, textAlign: 'center' }}>
            Continue to type 15 new sentences to get a better estimate of your performance.
          </div>
          <Button
            checkIsDisable={false}
            btnData={btnData}
            handleBtnClick={() => onContinueTest()}
          />
          {/* </div> */}
          {
            !isExistingCode && (
              <>
                <div>
                  <div style={{ fontWeight: 'bold', marginTop: 25 }}>Continue Code:</div>
                  - A unique code to track your typing performance across devices.<br />
                  - Use it to seamlessly continue tests on different devices.
                </div>
                <Divider variant="left" sx={{ marginTop: 2, marginBottom: 1 }} />
                <div style={{ fontWeight: 'bold', marginBottom: 10 }}>Your Continue Code:</div>
                <div>
                  <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', fontSize: 36, fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                    {histogramData.contCode}
                    <img
                      style={{ width: 50, cursor: 'pointer' }}
                      src={copyToClipImg}
                      alt="copytoclip"
                      onClick={() => copyToClipboard(histogramData.contCode)}
                    />
                  </div>
                  {copySuccess && <b>{copySuccess}</b>}
                </div>
                <Divider variant="left" sx={{ marginTop: 2, marginBottom: 1 }} />
                <div>
                  To receive your personalized link with the continue code, enter your email below:
                </div>
                <div style={{ marginBottom: 10, marginTop: 10 }}>
                  <input
                    // ref={inputRef}
                    type="email"
                    autoFocus
                    placeholder="name@example.com"
                    style={{
                      width: isMobile ? '96%' : '98%',
                      height: 40,
                      fontSize: isMobile ? 32 : 26,
                      fontFamily: 'monospace'
                    }}
                    value={email}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <Button
                  checkIsDisable={!emailRegex.test(email)}
                  btnData={sendEmailBtn}
                  handleBtnClick={() => onSendContCode()}
                />
              </>
            )
          }
        </Paper>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={isMobile ? 12 : 8}>
          <div className={resultClass.graphHeading}>
            Your typing performance in comparison with the other desktop participants
          </div>
          <Histogram globalWPM={histogramData.globalWPM} />
          <LineGraph
            avgWPM={parseInt(histogramData.avgWPM[0])}
            allWPM={histogramData.allWPM}
            globalWPM={histogramData.globalWPM}
            isMobile={isMobile}
          />
        </Grid>
      </Grid>

      <ResultHighlights
        restultPageLang={restultPageLang}
        histogramData={histogramData}
        isMobile={isMobile}
      />
      <div>
        <FAQ faqData={restultPageLang.faq} />
      </div>
      <div>
        <h2 className={resultClass.contactUsHeading}>Contact Us:</h2>
        <div className={resultClass.contactUsWrap}>
          <div>
            <div style={subHeading}>{contactDetails.name}</div>
            <div><span style={subHeading}>Email: </span>{contactDetails.email}</div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default WpmHistogramChart;
