import * as React from 'react';
import TextField from '@mui/material/TextField';

const Input = ({ fullwidth, value, onInputChange }) => {
    return (
        <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            fullWidth={fullwidth}
            value={value}
            onChange={onInputChange}
        />
    );
}

export default Input
