export const contactDetails = {
    name: 'Prof. Dr. Anna Maria Feit',
    email: ' feit@cs.uni-saarland.de',
    phone: '0681 302 70800',
    address: {
        group: 'Computational Interaction Group',
        uni: 'Saarland University',
        office: 'Campus E1.7 (Cluster of Excellence MMCI)',
        city: '66123 Saarbrücken, Germany'
    }

}