import React from 'react'
import Paper from '@mui/material/Paper'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'black',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}));


const ProgressBar = ({ heading, maxVal, curVal, val, isMob }) => {
    return (
        <Paper style={{ width: isMob ? 'auto' : '20%', margin: isMob ? 0 : '20px 0 0 20px', padding: 10, background: '#008080' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white' }}>
                <div style={{ fontSize: 24, fontWeight: 500, color: 'white', marginBottom: 10 }}>
                    {heading}
                </div>
                <div>
                    {`${curVal}/${maxVal}`}
                </div>
            </div>
            <BorderLinearProgress variant="determinate" value={val} />
        </Paper>
    )
}

export default ProgressBar
