import React, { useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';

export default function AccordionUsage({ faqData }) {
    const handleClick = (reference) => {
        // console.log('Clicked on reference:', reference[1]);
        scrollToSection(reference[1]);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        // console.log("section", section)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const supElements = document.querySelectorAll('sup');
        supElements.forEach((supElement) => {
            supElement.style.cursor = 'pointer';
            const reference = supElement.textContent.trim();
            supElement.addEventListener('click', () => handleClick(reference));
        });

        // Cleanup function to remove event listeners
        return () => {
            supElements.forEach((supElement) => {
                supElement.removeEventListener('click', () => handleClick());
            });
        };
    }, []);


    return (
        <div>
            <div>
                <h2 style={{ color: '#008080' }}>{faqData.heading}</h2>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {faqData.wpm.heading}
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontSize: 18 }}>
                        {parse(faqData.wpm.text)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {faqData.wpmAge.heading}
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontSize: 18 }}>
                        {parse(faqData.wpmAge.text)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {faqData.errorRate.heading}
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontSize: 18 }}>
                        {parse(faqData.errorRate.text)}
                    </AccordionDetails>
                </Accordion>
            </div>
            <div>
                <h2 style={{ color: '#008080' }}>References:</h2>
                <div id="1">{parse(faqData.references.one)}</div>
                <div id="2">{parse(faqData.references.two)}</div>
                <div id="3">{parse(faqData.references.three)}</div>
                <div id="4">{parse(faqData.references.four)}</div>
            </div>
        </div>
    );
}
