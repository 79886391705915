import axios from 'axios';


// NODE_ENV = 'dev'
// NODE_ENV= 'prod'

// const baseURL = 'http://127.0.0.1:8000'
// 'http://134.96.226.127:8000'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://typingtest.cs.uni-saarland.de:8001' : 'http://127.0.0.1:8000'

const api = axios.create({
  baseURL
});

export default api
