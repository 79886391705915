// Action types

// Home actions
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_TOKEN_REFRESH = 'AUTH_TOKEN_REFRESH';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_SYSTEM_LANGUAGE = 'SET_SYSTEM_LANGUAGE'


// Test Actions
export const SET_SENTENCE_LIST = 'SET_SENTENCE_LIST'

//Questionnaire Actions
export const SET_QUESTIONNAIRE_DATA = 'SET_QUESTIONNAIRE_DATA'

//User Actions
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_APP_LANG = 'SET_APP_LANG'
export const SET_EXISTING_CODE = 'SET_EXISTING_CODE'

//Results
export const SET_WPM_HISTOGRAM_DATA = 'SET_WPM_HISTOGRAM_DATA'
