import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import testReducer from './testReducer';
import userReducer from './userReducer';
import questionnaireReducer from './QuestionnaireReducer';
import resultsReducer from './resultsReducer';

const appReducer = combineReducers({
  home: homeReducer,
  test: testReducer,
  user: userReducer,
  questionnaire: questionnaireReducer,
  results: resultsReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
