export const resultsTxtData_en = {
    buttons: {
        continue: "Try again",
        heading: "Continue to type 15 new sentences to get a better estimate of your performance."
    },
    retatke: {
        heading: "Do you want to retake the test on a different device?"
    },
    wpm: {
        heading: "Your typing speed is {0} words per minute. You type faster than  of participants &mdash;  of participants in your age group.",
        wpmText: "WPM:",
        wpmChartTitle: "All",
        wpmAgechartTitle: "Your age group:  -  years old",

    },
    errorRate: {
        heading: "You left  of characters uncorrected.You make fewer errors than of participants.",
        errorRateText: "Errors:"
    },
    maxwpm: {
        heading: "Fastest sentence:",
        errors: "Errors",
        wpm: "WPM"
    },
    minWpm: {
        heading: "Slowest sentence:",
        errors: "Errors",
        wpm: "WPM"
    },
    maxerrorrate: {
        heading: "Sentence with highest error rate:",
        errors: "Errors",
        wpm: "WPM"
    },
    faq: {
        heading: "FAQ",
        wpm: {
            heading: "What is WPM ?",
            text: 'WPM, or <a href="https://en.wikipedia.org/wiki/Words_per_minute" target="_blank">words per minute</a>, is the measure of your typing speed. For comparison, the typing speed of a skilled computer user is typically between 50 and 90 WPM, with some even reaching over 120 WPM <sup>[1]</sup>. In comparison, we speak at a speed of 160 WPM, for example, when giving a presentation or instructions <sup>[2]</sup>. The WPM of each sentence is calculated by taking the time difference between the first and last keystroke, and dividing it by the total number of characters in the final input. This results in the average speed per keystroke. As common in the literature, we use 5 characters as the average word length to convert the typing speed into words per minute (see also <sup>[1, 4]</sup>).',
        },
        wpmAge: {
            heading: "How does age affect typing speed ?",
            text: " Motor and cognitive abilities decrease with age, which also affects our ability to process text and press keys on the keyboard.However, exercise plays a major role in typing speed.The more we type, the more correct and effective we write.Studies by professionally trained typists on typewriters show us that experience and practice can compensate for a loss of motor and cognitive skills over the years <sup> [3]</sup>.<br> <br> In contrast, we see large differences in speed when typing on a mobile phone between different age groups<sup>[4]</sup>, as older people do not have an experience advantage there. The mobile phone keyboard, as we use it today, is only about 10-15 years old. Overall, younger people have used the keyboard more than older generations over this time. In addition, we find it harder to learn new skills, such as typing with two thumbs, as we grow older. "
        },
        errorRate: {
            heading: "What is error rate?",
            text: 'Error rate is the percentage of incorrectly entered characters (not words). To calculate your error rate, each sentence you enter is compared to the sentence displayed to calculate the "edit distance", a value that measures the number of different characters between the two sentences. For this purpose we use the <a target="_blank" href="https://en.wikipedia.org/wiki/Levenshtein_distance">Levenshtein distance</a>. A percentage of the error is obtained by comparing the edit distance with the total number of characters (of the typed or displayed sentence, whichever is longer).'

        },
        references: {
            one: "[1] Dhakal, V., Feit, A.M., Kristensson, P.O., & Oulasvirta, A. (2018). <b>Observations on Typing from 136 Million Keystrokes.</b> In <i>Proceedings of the 2018 CHI Conference on Human Factors in Computing Systems (CHI ''18)</i>, Paper No. 646.",
            two: "[2] Williams, J.R. (1998). <b>Guidelines for the Use of Multimedia in Instruction.</b> In <i>Proceedings of the Human Factors and Ergonomics Society Annual Meeting</i>, Vol 42, Issue 20, pp. 1447-1451.",
            three: "[3] Salthouse, T.A. (1984). <b>Effects of Age and Skill in Typing.</b> <i>Journal of Experimental Psychology: General</i>, 113(3), 345-371.",
            four: "[4] Palin, K., Feit, A.M., Kim, S., Kristensson, P.O., & Oulasvirta, A. (2019). <b>How do People Type on Mobile Devices?: Observations from a Study with 37,000 Volunteers.</b> In <i>Proceedings of the 21st International Conference on Human-Computer Interaction with Mobile Devices and Services (MobileHCI ''19)</i>, Article 9."
        }
    },
}

export const resultsTxtData_de = {
    buttons: {
        continue: "Nochmal versuchen",
        heading: "Tippen Sie 15 weitere Sätze, um eine bessere Einschätzung Ihrer Geschwindigkeit zu erhalten."
    },
    retatke: {
        heading: "Wollen Sie den Test auf einem anderen Gerät wiederholen?"
    },
    wpm: {
        heading: "Ihre Tippgeschwindigkeit ist {0} Wörter pro Minute. Sie tippen schneller als der Teilnehmer &mdash; der Teilnehmer in Ihrer Altersgruppe.",
        wpmText: "WPM:",
        wpmChartTitle: "Alle",
        wpmAgechartTitle: "Ihre Altersgruppe: 10 - 20 Jährige",

    },
    errorRate: {
        heading: "Sie haben {0} der Zeichen nicht korrigiert. Sie machen weniger Fehler als der Teilnehmer.",
        errorRateText: "Fehler:"
    },
    maxwpm: {
        heading: "Schnellster Satz",
        errors: "Fehler",
        wpm: "WPM"
    },
    minWpm: {
        heading: "Langsamster Satz",
        errors: "Fehler",
        wpm: "WPM"
    },
    maxerrorrate: {
        heading: "Satz mit höchster Fehlerquote",
        errors: "Fehler",
        wpm: "WPM"
    },
    faq: {
        heading: "FAQs",
        wpm: {
            heading: "Was ist WPM?",
            text: 'WPM, oder <a href="https://de.wikipedia.org/wiki/Anschl%C3%A4ge_pro_Minute" target="_blank">Wörter pro Minute</a>, ist das Maß für Ihre Tippgeschwindigkeit. Zum Vergleich: Die Tippgeschwindigkeit eines geübten Computer Nutzers liegt normalerweise zwischen 50 und 90 WPM, wobei einige sogar über 120 WPM erreichen<sup>[1]</sup>. Im Vergleich dazu sprechen wir zum Beispiel mit einer Geschwindigkeit von 160 WPM, wenn wir eine Präsentation oder Anweisung geben<sup>[2]</sup>. Die WPM jedes Satzes wird berechnet, indem man die Zeitdifferenz zwischen dem ersten und letzten Tastendruck nimmt und diese durch die Gesamtzahl der Zeichen in der endgültigen Eingabe teilt. Daraus ergibt sich die Durchschnittsgeschwindigkeit per Tastendruck. Wie in der Literatur üblich, verwenden wir 5 Zeichen als durchschnittliche Wortlänge um dann die Tippgeschwindigkeit in Wörtern pro Minute umzurechnen (siehe auch <sup>[1,4]</sup>).'
        },
        wpmAge: {
            heading: "Wie beeinflusst das Alter die Tippgeschwindigkeit?",
            text: "Motorische und kognitive Fähigkeiten nehmen mit zunehmendem Alter ab, was auch unsere Fähigkeit beeinflusst Text zu verarbeiten und Tasten auf der Tastatur zu drücken. Allerdings spielt bei der Tippgeschwindigkeit die Übung eine große Rolle. Je mehr wir tippen, desto korrekter und effektiver schreiben wir. Studien von professionell geschulten Schreibkräften auf Schreibmaschinen zeigen uns, dass Erfahrung und Übung einen Verlust an motorischen und kognitiven Fähigkeiten über die Jahre ausgleichen kann<sup>[3]</sup>.<br><br>Im Gegensatz dazu sehen wir beim Tippen auf dem Handy große Unterschiede zwischen den verschiedenen Altersgruppen<sup>[4]</sup>, da ältere Personen dort keinen Erfahrungsvorteil haben. Die Handytastatur, wie wir sie heute nutzen, ist erst ca. 10–15 Jahre alt. Insgesamt gesehen haben jüngere Menschen über diese Zeit die Tastatur mehr genutzt als ältere Generationen. Zusätzlich fällt es uns schwerer neue Fähigkeiten, wie das Tippen mit zwei Daumen, zu lernen, wenn wir älter werden."
        },
        errorRate: {
            heading: "Was ist eine Fehlerquote?",
            text: 'Die Fehlerquote ist der Prozentsatz der falsch eingegebenen Zeichen (nicht der Wörter). Um Ihre Fehlerquote zu berechnen, wird jeder von Ihnen eingegebene Satz mit dem angezeigten Satz verglichen um die sogenannte "edit distance" zu berechnen, ein Wert, der die Anzahl sich unterscheidender Zeichen zwischen den zwei Sätzen misst. Dazu nutzen wir die <a target="_blank" href="https://de.wikipedia.org/wiki/Levenshtein-Distanz">Levenshtein Distanz</a>. Ein Prozentsatz des Fehlers ergibt sich aus dem Vergleich der edit distance mit der Gesamtanzahl der Zeichen (des getippten oder angezeigten Satzes, je nachdem welcher länger ist).',
        },
        references: {
            one: "[1] Dhakal, V., Feit, A.M., Kristensson, P.O., & Oulasvirta, A. (2018). <b>Observations on Typing from 136 Million Keystrokes.</b> In <i>Proceedings of the 2018 CHI Conference on Human Factors in Computing Systems (CHI ''18)</i>, Paper No. 646.",
            two: "[2] Williams, J.R. (1998). <b>Guidelines for the Use of Multimedia in Instruction.</b> In <i>Proceedings of the Human Factors and Ergonomics Society Annual Meeting</i>, Vol 42, Issue 20, pp. 1447-1451.",
            three: "[3] Salthouse, T.A. (1984). <b>Effects of Age and Skill in Typing.</b> <i>Journal of Experimental Psychology: General</i>, 113(3), 345-371.",
            four: "[4] Palin, K., Feit, A.M., Kim, S., Kristensson, P.O., & Oulasvirta, A. (2019). <b>How do People Type on Mobile Devices?: Observations from a Study with 37,000 Volunteers.</b> In <i>Proceedings of the 21st International Conference on Human-Computer Interaction with Mobile Devices and Services (MobileHCI ''19)</i>, Article 9."
        }
    },

}


// views.results.links.emailsubject=Typing%20Test%3A%20Personal%20continue%20code
// views.results.links.emailbody=Please%2C%20use%20the%20following%20link%20to%20take%20the%20test%20on%20a%20different%20device%3A%0Ahttps%3A%2F%2Ftypingtest.aalto.fi%3Fcc%3D
// views.results.headings.infobox=Your typing performance in comparison to other <span id="device-disclaimer">mobile</span> participants.
// views.results.headings.wpm=Your typing speed is <span id="user_wpm">{0}</span> words per minute. You type faster than <span id="wpm_compare"></span> of participants<span id="wpm_age_compare_sentence"> &mdash; <span id="wpm_age_compare"></span> of participants in your age group.
// views.results.headings.errorrate = You left < span id = "user_err" > { 0}</span > of characters uncorrected.You make fewer errors than < span id = "err_compare" ></span > of participants.
// views.results.texts.faq.wpm = WPM, or < a href = "https://en.wikipedia.org/wiki/Words_per_minute" target = "_blank" > words per minute</a >, is the measure of your typing speed.For comparison, the typing speed of a skilled computer user is typically between 50 and 90 WPM, with some even reaching over 120 WPM < sup > [1]</sup >.In comparison, we speak at a speed of 160 WPM, for example, when giving a presentation or instructions < sup > [2]</sup >.The WPM of each sentence is calculated by taking the time difference between the first and last keystroke, and dividing it by the total number of characters in the final input.This results in the average speed per keystroke.As common in the literature, we use 5 characters as the average word length to convert the typing speed into words per minute(see also < sup > [1, 4]</sup >).
