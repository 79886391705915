import {
    autocomp,
    predict,
    swype,
    type_both,
    type_index,
    type_left,
    type_middle,
    type_right,
    type_thumb
} from '../../components/UI/Icons'

export const nativeLanguageOptions = [
    "Abkhazian",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Arabic",
    "Aragonese",
    "Armenian",
    "Assamese",
    "Avaric",
    "Avestan",
    "Aymara",
    "Azerbaijani",
    "Bambara",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Chamorro",
    "Chechen",
    "Chichewa",
    "Chinese",
    "Church Slavic",
    "Chuvash",
    "Cornish",
    "Corsican",
    "Cree",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi",
    "Dutch",
    "Dzongkha",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Faroese",
    "Fijian",
    "Finnish",
    "French",
    "Fulah",
    "Galician",
    "Ganda",
    "Georgian",
    "German",
    "German (Austria)",
    "German (Switzerland)",
    "Greek",
    "Guaraní",
    "Gujarati",
    "Haitian",
    "Hausa",
    "Hebrew",
    "Herero",
    "Hindi",
    "Hiri Motu",
    "Hungarian",
    "Icelandic",
    "Ido",
    "Igbo",
    "Indonesian",
    "Interlingua",
    "Interlingue",
    "Inuktitut",
    "Inupiaq",
    "Irish",
    "Italian",
    "Japanese",
    "Javanese",
    "Kalaallisut",
    "Kannada",
    "Kanuri",
    "Kashmiri",
    "Kazakh",
    "Khmer",
    "Kikuyu",
    "Kinyarwanda",
    "Kirghiz",
    "Komi",
    "Kongo",
    "Korean",
    "Kuanyama",
    "Kurdish",
    "Lao",
    "Latin",
    "Latvian",
    "Limburgan",
    "Lingala",
    "Lithuanian",
    "Luba-Katanga",
    "Luxembourgish",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Manx",
    "Maori",
    "Marathi",
    "Marshallese",
    "Mongolian",
    "Nauru",
    "Navajo",
    "Ndonga",
    "Nepali",
    "North Ndebele",
    "Northern Sami",
    "Norwegian",
    "Norwegian Bokmål",
    "Norwegian Nynorsk",
    "Occitan",
    "Ojibwa",
    "Oriya",
    "Oromo",
    "Ossetian",
    "Pali",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romanian",
    "Romansh",
    "Rundi",
    "Russian",
    "Samoan",
    "Sango",
    "Sanskrit",
    "Sardinian",
    "Scottish Gaelic",
    "Serbian",
    "Shona",
    "Sichuan Yi",
    "Sindhi",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "South Ndebele",
    "Southern Sotho",
    "Spanish",
    "Sundanese",
    "Swahili",
    "Swati",
    "Swedish",
    "Tagalog",
    "Tahitian",
    "Tajik",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tibetan",
    "Tigrinya",
    "Tonga",
    "Tsonga",
    "Tswana",
    "Turkish",
    "Turkmen",
    "Twi",
    "Uighur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Volapük",
    "Walloon",
    "Welsh",
    "Western Frisian",
    "Wolof",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang",
    "Zulu"
]

export const nativeLanguageOptions_de = [
    "Abchasisch",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanisch",
    "Amharisch",
    "Arabisch",
    "Aragonesisch",
    "Armenisch",
    "Aserbaidschanisch",
    "Assamesisch",
    "Avarisch",
    "Avestisch",
    "Aymara",
    "Bambara",
    "Baschkirisch",
    "Baskisch",
    "Bengalisch",
    "Bihari",
    "Birmanisch",
    "Bislama",
    "Bokmål",
    "Bosnisch",
    "Bretonisch",
    "Bulgarisch",
    "Chamorro",
    "Chichewa",
    "Chinesisch",
    "Cree",
    "Deutsch",
    "Deutsch (Schweiz)",
    "Deutsch (Österreich)",
    "Dhivehi",
    "Dzongkha",
    "Dänisch",
    "Englisch",
    "Esperanto",
    "Estnisch",
    "Ewe",
    "Fidschi",
    "Finnisch",
    "Französisch",
    "Fulfulde",
    "Färöisch",
    "Galicisch",
    "Georgisch",
    "Griechisch",
    "Grönländisch",
    "Guaraní",
    "Gujarati",
    "Haitianisch",
    "Hausa",
    "Hebräisch",
    "Hindi",
    "Hiri Motu",
    "Ido",
    "Igbo",
    "Indonesisch",
    "Interlingua",
    "Interlingue",
    "Inuktitut",
    "Inupiaq",
    "Irisch",
    "isiXhosa",
    "isiZulu",
    "Isländisch",
    "Italienisch",
    "Japanisch",
    "Javanisch",
    "Jiddisch",
    "Kannada",
    "Kanuri",
    "Kasachisch",
    "Kashmiri",
    "Katalanisch",
    "Khmer",
    "Kikongo",
    "Kikuyu",
    "Kiluba",
    "Kinyarwanda",
    "Kirchenslawisch",
    "Kirgisisch",
    "Kirundi",
    "Komi",
    "Koreanisch",
    "Kornisch",
    "Korsisch",
    "Kroatisch",
    "Kurdisch",
    "Laotisch",
    "Latein",
    "Lettisch",
    "Limburgisch",
    "Lingála",
    "Litauisch",
    "Luganda",
    "Luxemburgisch",
    "Malagasy",
    "Malaiisch",
    "Malayalam",
    "Maltesisch",
    "Manx",
    "Maori",
    "Marathi",
    "Marshallesisch",
    "Mazedonisch",
    "Mongolisch",
    "Nauruisch",
    "Navajo",
    "Ndonga",
    "Nepali",
    "Niederländisch",
    "Nord-Ndebele",
    "Nordsamisch",
    "Norwegisch",
    "Nynorsk",
    "Ojibwe",
    "Okzitanisch",
    "Oriya",
    "Oromo",
    "oshiKwanyama",
    "Ossetisch",
    "Otjiherero",
    "Pali",
    "Panjabi",
    "Paschtunisch",
    "Persisch",
    "Polnisch",
    "Portugiesisch",
    "Quechua",
    "Romanisch",
    "Rumänisch",
    "Russisch",
    "Samoanisch",
    "Sango",
    "Sanskrit",
    "Sardisch",
    "Schottisch-gälisch",
    "Schwedisch",
    "Serbisch",
    "Sesotho",
    "Setswana",
    "Shona",
    "Sindhi",
    "Singhalesisch",
    "Siswati",
    "Slowakisch",
    "Slowenisch",
    "Somali",
    "Spanisch",
    "Sundanesisch",
    "Swahili",
    "Süd-Ndebele",
    "Tadschikisch",
    "Tagalog",
    "Tahitianisch",
    "Tamil",
    "Tatarisch",
    "Telugu",
    "Thai",
    "Tibetisch",
    "Tigrinya",
    "Tongaisch",
    "Tschechisch",
    "Tschetschenisch",
    "Tschuwaschisch",
    "Tshivenda",
    "Turkmenisch",
    "Twi",
    "Türkisch",
    "Uigurisch",
    "Ukrainisch",
    "Ungarisch",
    "Urdu",
    "Usbekisch",
    "Vietnamesisch",
    "Volapük",
    "Walisisch",
    "Wallonisch",
    "Weißrussisch",
    "Westfriesisch",
    "Wolof",
    "Xitsonga",
    "Yi",
    "Yoruba",
    "Zhuang"
]

export const keyboardTypeList = ['Mobile phone', 'Tablet', 'Laptop', 'Desktop', 'Add-on physical keyboard for mobile devices', 'Other']
export const keyboardTypeList_de = ['Handy', 'Tablet', 'Laptop', 'Desktop PC', 'Externe Tastatur für Mobilgeräte', 'Sonstiges']

export const keyboardLayoutList = ['QWERTZ', 'QWERTY', 'AZERTY', 'Other']
export const keyboardLayoutList_de = ['QWERTZ', 'QWERTY', 'AZERTY', 'Sonstiges']


export const typeFingerList =
    ['Right thumb', 'Right index finger', 'Right middle finger', 'Right ring finger', 'Right little finger',
        'Left thumb', 'Left index finger', 'Left middle finger', 'Left ring finger', 'Left little finger', "I don''t know"]

export const typeFingerListMobile = [
    {
        name: 'Thumb(s)',
        src: type_thumb
    },
    {
        name: 'Index Finger(s)',
        src: type_index
    },
    {
        name: 'Middle Finger(s)',
        src: type_middle
    },
    {
        name: 'More than two fingers',
        src: ''
    },
    {
        name: 'Specify other typing method',
        src: ''
    },
]

export const typeFingerList_de = [
    'Rechter Daumen',
    'Rechter Zeigefinger',
    'Rechter Mittelfinger',
    'Rechter Ringfinger',
    'Rechter kleiner Finger',
    'Linker Daumen',
    'Linker Zeigefinger',
    'Linker Mittelfinger',
    'Linker Ringfinger',
    'Linker kleiner Finger',
    'Das weiß ich nicht'
]

export const genderList = ['Male', 'Female', 'Non-binary', 'Prefer not to specify']
export const genderList_de = ['Männlich', 'Weiblich', 'Divers', 'Keine Angabe']

export const typeTestLangList = ['Always', 'Usually', 'Sometimes', 'Rarely', 'Never']
export const typeTestLangList_de = ['Immer', 'In der Regel', 'Manchmal', 'Selten', 'Nie']

export const typingSpeedList = [1,2,3,4,5,6,7]

export const typingAccuracyList = [1,2,3,4,5,6,7]

export const keyboardAppList = ['Yes', 'No', 'Not Sure']
export const keyboardAppList_de = ['Ja', 'Nein', 'Nicht sicher']

export const keyboardFeaturesWhyList = ['To be faster', 'To make fewer mistakes', 'It is less physically demanding',
    'I find it easier / I have to think less', 'Other', 'I do not use any', 'Not sure']

export const keyboardFeaturesWhyList_de = ['Um schneller zu sein', 'Um weniger Fehler zu machen', 'Es ist körperlich weniger anstrengend', 'Es fällt mir leichter / ich muss weniger nachdenken'
    , 'Sonstiges', 'Ich benutze keine', 'Nicht sicher']

export const ageList = Array.from({ length: 111 }, (_, i) => i + 10);

// export const keyboardTypingTimeList = Array.from({ length: 13 }, (_, index) => index);
export const keyboardTypingTimeList = Array.from({ length: 14 }, (_, index) => index).map(num => num === 13 ? '>13' : num);


export const keyboardFeaturesList = [
    {
        name: 'Swipe',
        info: 'To type a word, you continuously trace from one letter to another, without lifting the finger.',
        img: swype
    },
    {
        name: 'Prediction',
        info: 'The keyboard makes suggestions for the word you are typing or will type next. It shows several options which you can choose from but does not change your text automatically.',
        img: predict
    },
    {
        name: 'Autocorrection',
        info: 'After pressing space, the keyboard automatically changes the typed word to correct its spelling or complete it.',
        img: autocomp
    },
    {
        name: 'Other',
        info: ''
    },
    {
        name: 'No'
    },
    {
        name: 'Not Sure'
    }]
export const keyboardFeaturesList_de = ['Swype', 'Wortvorhersage', 'Autokorrektur', 'Sonstiges', 'Nein', 'Nicht sicher']


export const typingHandsList = ["One hand, left", "One hand, right", "Both hands"]

export const keyboardApps = ['Gboard – the Google Keyboard', 'Microsoft SwiftKey AI Keyboard', 'Bobble AI Keyboard', 'Other']
