import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppRouter from './AppRouter'
import AppHeader from './AppHeader'
import { setSystemLanguage } from '../../store/actions/homeActions';
import {
  setAppLang
} from '../../store/actions/userActions';
import { cixLogoImg } from '../UI/Icons'
import styles from './App.module.css'

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    dispatch(setSystemLanguage(window.navigator.language))
    const hostname = window.location.hostname;
    if (hostname === 'typingtest.cs.uni-saarland.de') {
      dispatch(setAppLang("English"))
    } else if (hostname === 'tipptest.cs.uni-saarland.de') {
      dispatch(setAppLang("Deutsch"))
    }
  }, [])

  const handleImageClick = () => {
    window.location.href = 'https://cix.cs.uni-saarland.de/';
  };

  return (
    <div className={styles.appContainer} >
      <AppHeader />
      <div className={styles.appPageContainer}>
        <AppRouter />
      </div>
      <div>
        <img src={cixLogoImg} alt="cixLogo" className={styles.logoImg} onClick={handleImageClick} />
      </div>
    </div>
  );
}

export default App;
