import * as actionTypes from '../actions/actionTypes';
import { updateObject, selectDisplayFields } from '../../shared/Utility';
import initialState from './initialState';
import { testTxtData_en, testTxtData_de } from '../../shared/Lang/TestText'
import { questPageData_en, questPageData_de, formTextLables_en, formTextLables_de, } from '../../shared/Lang/QuestionnaireText'
import { resultsTxtData_en, resultsTxtData_de } from '../../shared/Lang/ResultsTxt'
import { headerTxt_en, headerTxt_de } from '../../shared/Lang/AppHeaderTxt'

export const setUserData = (state, action) => {
  return (
    updateObject(state, {
      uid: action.data.uid,
      continueCode: action.data.continueCode,
      questionFormLang: action.data.formVals ? action.data.formVals : (action.data.appLang === 'English') ? formTextLables_en : formTextLables_de
    })
  )
};

export const setAppLang = (state, action) => {
  return (
    updateObject(state, {
      appLang: action.data,
      testPageLang: (action.data === 'English') ? testTxtData_en : testTxtData_de,
      questionPageLang: (action.data === 'English') ? questPageData_en : questPageData_de,
      questionFormLang: (action.data === 'English') ? formTextLables_en : formTextLables_de,
      restultPageLang: (action.data === 'English') ? resultsTxtData_en : resultsTxtData_de,
      headerLang: (action.data === 'English') ? headerTxt_en : headerTxt_de,
    })
  )
};


export const setExistingCode = (state, action) => {
  return (
    updateObject(state, {
      isExistingCode: action.data
    }))
};




const testReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_DATA: return setUserData(state, action);
    case actionTypes.SET_APP_LANG: return setAppLang(state, action);
    case actionTypes.SET_EXISTING_CODE: return setExistingCode(state, action);
    default:
      return state;
  }
};

export default testReducer;
