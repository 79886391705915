import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/Utility';
import initialState from './initialState';

export const setWPMHistogramData = (state, action) => {
  return (
    updateObject(state, {
        wpmHistogramData: action.data,
    })
  )
};

const testReducer = (state = initialState.test, action) => {
  switch (action.type) {
    case actionTypes.SET_WPM_HISTOGRAM_DATA: return setWPMHistogramData(state, action);
    default:
      return state;
  }
};

export default testReducer;
