export const testTxtData_en = {
    sentence: 'Sentence:',
    loading: 'Loading..',
    wpm: 'WPM:',
    defaultWPM: 0,
    errorrate: 'Errors:',
    defaultErrRate: '0%',
    instructions: 'Read through the sentence, then type it out as fast and as accurately as you can.',
    next: 'Next',
    val: 'I have received your messages and will respond accordingly.'
}

export const testTxtData_de = {
    sentence: 'Satz:',
    loading: 'Laden...',
    wpm: 'WPM:',
    defaultWPM: 0,
    errorrate: 'Fehler:',
    defaultErrRate: '0%',
    instructions: 'Lesen Sie den Satz durch und tippen Sie ihn dann so schnell und korrekt wie möglich.',
    next: 'Weiter',
    val: 'Wenn du willst, können wir uns auch schon früher treffen.'
}

export const testTxtData_fi = {
    sentence: 'Tuomio:',
    loading: 'Ladataan..',
    wpm: 'WPM:',
    defaultWPM: 0,
    errorrate: 'Virheet:',
    defaultErrRate: '0%',
    instructions: 'Lue lause läpi ja kirjoita se sitten niin nopeasti ja tarkasti kuin pystyt.',
    next: 'Seuraava',
    val: 'Ei siis ole kyse opintojen haastavuudesta vaan sisällöstä'
}
