import langs from 'langs';
import { languageMapping_de, languageMapping_en } from './Lang/LangList';
import { formTextLables_de, formTextLables_en } from './Lang/QuestionnaireText';
import api from './apiSetup'

export const getDefaultLang = (systemLanguage) => {
  // Extract the language code from the system language
  const languageCode = systemLanguage.split('-')[0];

  // Use the langs library to get the language name from the code
  const languageInfo = langs.where('1', languageCode);

  // If languageInfo is found, return the language name; otherwise, return a default language
  return languageInfo ? languageInfo.name : "English"; // You can set a default language here
};


export const getGermanLanguageName = () => {
  const lang = window.navigator.language;
  // console.log("lang", lang)
  if (lang.includes("en")) {
    return "Englisch";
  }
  return languageMapping_de[lang] || "Deutsch";
}

export const getEnglishLanguageName = () => {
  const lang = window.navigator.language;
  if (lang.includes("en")) {
    return "English";
  }
  return languageMapping_en[lang] || "English";
}

export const selectDisplayFields = async (appLang, cc, device) => {
  const formTextLables = (appLang === 'English') ? formTextLables_en : formTextLables_de
  const copyFormTxtLabels = JSON.parse(JSON.stringify(formTextLables))
  const elementsToRemove = ['age', 'gender', 'nativeLanguages']
  let tempArray = copyFormTxtLabels.keyboardData

  try {
    const response = await api.get(`/isTestTaken?continueCode=${cc}&device_type=${device}`);
    // console.log(response.data.testTaken, "API RESPONSE UTIL")
    if (response.data.testTaken === true) {
      // console.log(copyFormTxtLabels, "copyFormTxtLabels")
      tempArray = copyFormTxtLabels.keyboardData.filter(eachObj => eachObj.label !== 'keyboardTypingTime')
      // console.log(tempArray, "temp array")
    }
    // return response.data.isValidCode
  } catch (error) {
    // setErrMsg("Invalid Continue code!")
    throw error
    // return null
  }

  const updatedArray = copyFormTxtLabels.userData.filter(eachObj => !elementsToRemove.includes(eachObj.label))

  copyFormTxtLabels.userData = updatedArray
  copyFormTxtLabels.keyboardData = tempArray
  console.log(copyFormTxtLabels, "BEFORE RETURN")
  return (copyFormTxtLabels)
  // console.log(updatedArray, 'updatedArray')
}

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});
