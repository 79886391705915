import React from 'react'
import { Paper, Divider } from '@mui/material';
import resultClass from './Results.module.css'

const ResultBar = ({ device, lang, wpm, backspace, errRate }) => {
    const resultBarData = [
        {
            title: "Device",
            val: device
        },
        {
            title: "Language",
            val: lang
        },
        {
            title: 'Words Per Minute',
            val: wpm
        },
        {
            title: 'Backspace',
            val: backspace
        },
        {
            title: 'Error Rate',
            val: errRate
        },
    ]
    return (
        <div className={resultClass.resultBarContainer}>
            <Paper sx={{ padding: 3, width: 'fit-content', display: 'flex' }}>
                {
                    resultBarData.map((each, index) => (
                        <>
                            <div className={resultClass.resultBarElement} >
                                <div className={resultClass.resultBarEleTitle}>
                                    {each.title}
                                </div>
                                <div className={resultClass.resultBarEleVal}>
                                    {each.val}
                                </div>
                            </div>
                            <Divider orientation="vertical" variant="fullwidth" flexItem sx={{ padding: 1 }} />
                        </>
                    ))
                }
            </Paper>
        </div>
    )
}

export default ResultBar