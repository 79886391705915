import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'black',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}));


const ProgressBar = ({ heading, maxVal, curVal, langData, wpm, errRate, isMob }) => {

    return (
        <Paper style={{ width: isMob ? '95%' : '20%', padding: 10, background: '#008080' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', color: 'white', alignItems: 'center' }}>
                <div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        color: 'white'
                    }}
                    >
                        <div style={{ fontSize: 18, fontWeight: 500, marginBottom: 10 }}>
                            {langData.sentence}
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 500, marginLeft: isMob ? 0 : 20, marginBottom: 10 }}>
                            {`${curVal}/${maxVal}`}
                        </div>
                    </div>
                    <BorderLinearProgress variant="determinate" value={(curVal * 100) / 15} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 14, }}>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>WPM</div>
                    <div style={{ fontWeight: 500 }}>{wpm}</div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column', alignItems: 'center', fontSize: 14, fontWeight: 'bold'
                }}>
                    <div style={{ fontSize: 16 }}>{langData.errorrate}</div>
                    <div style={{ fontWeight: 500 }}>{errRate}%</div>
                </div>
            </div>
        </Paper>
    )
}

export default ProgressBar
