/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper'
import Button from '../UI/Button'
import { QUESTION, RESULTS } from '../../constants/RouteConst'
import { setWPMHistogramData } from '../../store/actions/resultActions'
import ProgressWPM from '../UI/ProgressCard/ProgressWPM'
import { useSelector, useDispatch } from 'react-redux';
import api from '../../shared/apiSetup'
import { getResultsData } from '../../shared/apiUtil'
import testClass from './Test.module.css'

let logDataToBeSubmitted = []
let keys = {};

const Test = () => {
    const dispatch = useDispatch();

    const sentenceList = useSelector(state => state.test.sentenceList);
    const isMobile = useSelector(state => state.home.isMobileDev);
    const uid = useSelector(state => state.user.uid);
    const continueCode = useSelector(state => state.user.continueCode);
    const appLang = useSelector(state => state.user.appLang);
    const testPageLang = useSelector(state => state.user.testPageLang);
    const wpmHistData = useSelector(state => state.results.wpmHistogramData)

    const navigate = useNavigate();
    const inputRef = useRef(null);

    const [firstKeyPressTime, setFirstKeyPressTime] = useState(null);
    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);
    const [nextClick, setNextClick] = useState(false);
    const [inputVal, setInputVal] = useState('');
    const [txtData, setTxtData] = useState(testPageLang)
    const [sentenceArr, setSentenceArr] = useState(sentenceList)
    const [sentenceIndex, setSentenceIndex] = useState(0)
    const [wpm, setWpm] = useState(0)
    const [wpmList, setWpmList] = useState([])
    const [errorRate, setErrRate] = useState(0)
    const [errorRateList, setErrRateList] = useState([])
    const [tsId, setTsId] = useState(null)


    // var ws = null;
    // ws = new WebSocket("ws://localhost:8000/ws");

    useEffect(() => {
        setSentenceArr(sentenceList)
    }, [sentenceList])

    useEffect(() => {
        if (wpmHistData) {
            setWpm(parseInt(wpmHistData.avgWPM))
            setErrRate(parseInt(wpmHistData.avgErrRate))
        }

    }, [wpmHistData])

    //TODO: stringify is not working. It currenlty returns an empty object string. 

    const getScreenOrientation = () => {
        const screenOrientation = window.screen.orientation;
        if (screenOrientation !== "undefined") {
            return screenOrientation.angle
            // return JSON.stringify(screenOrientation);
        }
        // NOTE: DEPRECATED CODE 
        // if (typeof(window.orientation) != "undefined") {
        //     return window.orientation;
        // }
        else {
            return -1;
        }
    }

    function whichPressed(keys) {
        var pressed = [];
        for (var k in keys) {
            if (keys[k] === true) {
                pressed.push(k);
            }
        }
        return pressed;
    }

    const getEvtData = (testSecId, event, time, input, inputEvt) => {
        if (testSecId) {
            const evtObj = {
                testSectionId: testSecId, // Error To Do : first evtObj has testSectionId value as null
                eventTimestamp: time,
                eventType: String(event.type == null ? '' : event.type), // catches both null and undefined
                eventKey: String(event.key == null ? '' : event.key), // catches both null and undefined
                eventCode: String(event.code == null ? '' : event.code), // catches both null and undefined
                eventData: inputEvt ? String(event.nativeEvent.data == null ? '' : event.nativeEvent.data) : String(event.data == null ? '' : event.data), // catches both null and undefined
                inputText: String(input),
                // DOUBT
                keyCodes: String(whichPressed(keys)),
                screenOrientation: getScreenOrientation(),
            }
            return evtObj
        } else {
            // console.log("test section id is null")
        }
    }


    const addTestSection = async (isFirstSentence) => {
        const tsData = {
            sentenceId: isFirstSentence ? sentenceList[0].id : sentenceList[sentenceIndex].id,
            continueCode: continueCode,
            participantId: uid
        }

        try {
            const response = await api.post('/addTestSection', tsData);
            if (response.data.id) {
                // setTsId(response.data.id)
                return Promise.resolve(response.data.id);
            } else {
                return Promise.reject("Response does not contain test section ID");
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    useEffect(() => {
        if (sentenceIndex === 0) {
            addTestSection(true).then((testSectionId) => {
                setTsId(testSectionId)
            }).catch((error) => {
                // Handle error if needed
                console.error('Error:', error);
            });
        }
    }, [])


    useEffect(() => {
        if (sentenceIndex > 0 && sentenceIndex < 15) {
            addTestSection(false).then((testSectionId) => {
                // Do something with the test section ID if needed
                setTsId(testSectionId)
            }).catch((error) => {
                // Handle error if needed
                console.error('Error:', error);
            });
        }
    }, [sentenceIndex])

    const handleKeyLog = (event) => {

        const currentTime = Date.now();

        // Check if this is the first key press
        if (firstKeyPressTime === null) {
            setFirstKeyPressTime(Date.now())
        }
        // Always update the last key press timestamp
        setLastKeyPressTime(Date.now());
        const evtData = getEvtData(tsId, event, currentTime, inputVal, false)
        if (evtData) {
            logDataToBeSubmitted.push(evtData);
        }
    };

    useEffect(() => {
        const inputElement = inputRef.current;

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                // e.preventDefault()
                handleNext(e.srcElement.value, true)
            } else {
                keys[e.which] = true;
                handleKeyLog(e)
            }
        }

        const handleKeyUp = (e) => {
            if (e.key !== 'Enter') {
                keys[e.which] = false;
                handleKeyLog(e)
            }
        }

        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown);
            //Deprecated feature
            // inputElement.addEventListener('keypress', handleKeyPress);
            inputElement.addEventListener('keyup', handleKeyUp);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown);
                inputElement.removeEventListener('keyup', handleKeyUp);
            }
        };
    }, [tsId, firstKeyPressTime, lastKeyPressTime])

    const submitLogData = async () => {
        if (logDataToBeSubmitted.length > 0) {
            try {
                const response = await api.post('/storeLogData', logDataToBeSubmitted);
                return true
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    const onInputChange = (e) => {
        e.preventDefault()
        const evtData = getEvtData(tsId, e, Date.now(), e.target.value, true)
        if (evtData) {
            logDataToBeSubmitted.push(evtData);
        }
        setInputVal(e.target.value)
    }

    const calculateAverage = (arr) => {
        if (arr.length === 0) {
            return 0; // Handle the case where the array is empty to avoid division by zero.
        }

        // Use the reduce function to sum up all elements in the array.
        var sum = arr.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue;
        }, 0);

        // Calculate the average by dividing the sum by the number of elements.
        var average = sum / arr.length;
        return average;
    }


    const submitUserInput = async (inputValue) => {
        if (inputValue.length > 0) {
            const userInputData = {
                tsId: tsId,
                inputVal: inputValue,
            }
            const wpmData = {
                inputVal: inputValue,
                inputTime: (lastKeyPressTime - firstKeyPressTime) / 1000,
                tsId: tsId,
                device: isMobile ? 'mobile' : 'desktop',
            }
            const avgWPMVal = {
                uid: uid
            }
            try {
                const resp = await api.post('/submitUserInput', userInputData);
                const tempErrRateList = [...errorRateList]
                tempErrRateList.push(resp.data.error_rate)
                const avgErrRate = calculateAverage(tempErrRateList)
                setErrRate(parseInt(avgErrRate))
                setErrRateList(tempErrRateList)
                const response = await api.post('/calculateWPM', wpmData);
                if (response.status === 200) {
                    // setWpm(parseInt(response.data.wpm))
                    const tempWpmList = [...wpmList]
                    tempWpmList.push(parseInt(response.data.wpm))
                    const avgErrRate = calculateAverage(tempWpmList)
                    setWpmList(tempWpmList)
                    setWpm(parseInt(avgErrRate))
                    setInputVal('')
                    setFirstKeyPressTime(null)
                    setLastKeyPressTime(null)
                    // const result = getDataForErr()
                    // const result = submitTestSectionData(resultWpm);
                    if (sentenceIndex < 14) {
                        logDataToBeSubmitted = []
                        setTsId(null)
                        setSentenceIndex(sentenceIndex + 1)
                    } else {
                        const response = await api.post('/updateAvgWPM', avgWPMVal);
                        if (wpmHistData) {
                            getResultsData(uid)
                                .then(resultData => {
                                    // Handle the result object here
                                    dispatch(setWPMHistogramData(resultData))
                                    navigate(RESULTS);
                                })
                                .catch(error => {
                                    // Handle any errors here
                                    console.error(error);
                                });
                        } else {
                            navigate(QUESTION);
                        }
                    }
                    setNextClick(false)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    //WebSocket code - currently on hold

    // useEffect(() => {
    // console.log(nextClick, "nextClick")
    // if (nextClick) {
    //     // ws.onopen = () => ws.send(JSON.stringify(logDataToBeSubmitted));
    //     // console.log({ inputVal: inputVal, inputTime: (lastKeyPressTime - firstKeyPressTime) / 1000 })
    //     ws.onopen = () => ws.send(JSON.stringify(
    //         { 
    //             inputVal: inputVal, 
    //             inputTime: (lastKeyPressTime - firstKeyPressTime) / 1000, 
    //             tsId: tsId,
    //             device: isMobile ? 'mobile' : 'desktop' 
    //         }
    //     ));
    //     ws.onmessage = (e) => {
    //         const resultWpm = JSON.parse(e.data)
    //         console.log("msggg", parseInt(resultWpm.wpm))
    //         setWpm(parseInt(resultWpm.wpm))
    //         setInputVal('')
    //         // const result = getDataForErr()
    //         // const result = submitTestSectionData(resultWpm);
    //         // console.log(result, "result")
    //         if (sentenceIndex < 14) {
    //             setSentenceIndex(sentenceIndex + 1)
    //         } else {
    //             navigate(QUESTION);
    //         }
    //         setNextClick(false)
    //     }
    // }
    // }, [nextClick])

    const btnData = {
        btnName: txtData.next,
        width: 125
    }

    const handleNext = (inputData, isEnter) => {
        const result = submitLogData()
        const inputValue = isEnter ? inputData : inputVal
        result.then(() => {
            // The promise has resolved
            submitUserInput(inputValue);
        });
    }

    return (
        <div className={testClass.testContainer}>
            <div className={testClass.testHeaderWrap}  >
                <div className={testClass.testInstr}>
                    {txtData.instructions}
                </div>
                <ProgressWPM
                    heading="Sentences"
                    maxVal={15}
                    curVal={sentenceIndex + 1}
                    errRate={errorRate}
                    wpm={wpm}
                    isMob={isMobile}
                    langData={txtData}
                />
            </div>
            <div className={testClass.testContentWrap} >
                <div>
                    <div className={testClass.testContent}>
                        <Paper className={testClass.testInputSectionWrap}>
                            <div
                                style={{ fontSize: isMobile ? 20 : 28 }}>
                                {(sentenceArr.length > 1) && sentenceArr[sentenceIndex].sentence}
                            </div>
                            <div className={testClass.testInputWrap}>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    autoFocus
                                    placeholder="Type here..."
                                    className={testClass.testInput}
                                    value={inputVal}
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </Paper>
                    </div>
                    <div className={testClass.nextBtnWrap}>
                        <Button btnData={btnData} handleBtnClick={() => handleNext(null, false)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Test
