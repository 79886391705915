// initial state

export default {
  home: {
    isAuthenticated: false,
    accessToken: null,
    isMobileDev: false,
    systemLanguage: '',
  },
  test: {
    sentenceList: []
  },
  questionnaire: {
    formData: {
      uid: null,
      age: null,
      gender: null,
      nativeLanguages: null,
      typeTestLanguage: null,
      keyboardType: null,
      keyboardLayout: null,
      keyboardTypingTime: null,
      typingHabitFingers: null,
      typingSpeed: null,
      typingAccuracy: null,
    }
  },
  user: {
    uid: null,
    continueCode: null,
    isExistingCode: false,
    appLang: "English",
    testPageLang: [],
    questionPageLang: [],
    questionFormLang: [],
    restultPageLang: [],
    headerLang: {}
  },
  results: {
    wpmHistogramData: null
  }
};
