import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { closeImg } from '../UI/Icons'
import Button from './Button'
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

function TermsAndConditionsDialog({ handleClose, handleAccept, consentData, consentFormData }) {
    const isMobile = useSelector(state => state.home.isMobileDev);

    const btnData = {
        btnName: consentFormData.button
    }

    function ConsentContent() {
        const content = [];
        for (const key in consentData) {
            if (key.includes("Heading")) {
                // Render headings for properties containing "Heading"
                content.push(<h4 key={key}>{parse(consentData[key])}</h4>);
            } else {
                // Render divs for other properties
                content.push(<div key={key}>{parse(consentData[key])}</div>);
            }
        }

        return <div>{content}</div>;
    }

    return (
        <Dialog open={true} onClose={handleClose}
            maxWidth="lg"
            //  sx={{ backgroundColor: '#d9d9d9'}}
            // fullScreen={isMobile}
            style={{ margin: isMobile ? 0 : "inherit" }}
        >
            <div style={{ background: '#D9D9D9' }}>
                <div
                    onClick={() => { handleClose() }}
                    style={{ float: 'right', margin: '10px 10px 0 0', cursor: 'pointer' }}
                >
                    <img src={closeImg} alt="closeImg" style={{ width: 30 }} />
                </div>
                <DialogTitle sx={{ color: '#000080', fontWeight: 600, textAlign: isMobile ? 'center' : 'inherit' }}>{consentFormData.heading} </DialogTitle>
                <div style={{ marginLeft: isMobile ? 0 : 26, textAlign: isMobile ? 'center' : 'left' }}>{parse(consentData.subHeading)}</div>
                <DialogContent sx={{ margin: isMobile ? '5px' : '25px 28px', background: 'white', padding: isMobile ? 0 : 'inherit' }}>
                    <DialogContentText
                        sx={{
                            maxHeight: isMobile ? 'calc(100vh - 200px)' : 200,
                            overflow: 'auto',
                            letterSpacing: isMobile ? 0 : 'inherit',
                            padding: 1,
                            textAlign: isMobile ? 'center' : ''
                        }}
                    >
                        {ConsentContent()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button handleBtnClick={handleAccept} btnData={btnData} />
                </DialogActions>
            </div>
        </ Dialog>
    );
}

export default TermsAndConditionsDialog;
