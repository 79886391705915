export const headerTxt_en = {
    name: 'Scientific Typing Test',
    moreInfo: "More Info",
    contactUs: "Contact Us",
    testDemo: "Test Demo",
    faq: "FAQ"
}

export const headerTxt_de = {
    name: "Wissenschaftlicher Tipptest",
    moreInfo: "Weitere Informationen",
    contactUs:  "Kontaktieren Sie uns",
    testDemo:  "Test-Demo",
    faq: "FAQ"
}

export const headerTxt_fi = {
    name: "Tieteellinen tyyppitesti",
    moreInfo: "Lisätietoja",
    contactUs: "Ota yhteyttä",
    testDemo:  "Testidemo",
    faq: "FAQ"
}





