import api from './apiSetup'

export const getResultsData = async (uid) => {
    let backspaceData 
    const resultPayload = {
        uid: uid
    }
    try {
        const response = await api.post('/max_backspace', resultPayload);
        // console.log(response, "BACKSPACE RESPONSE")
         backspaceData = response.data
         try {
            const response = await api.post('/getResultData', resultPayload);
            const resultPageData = {
                allWPM: response.data.allWPM.result,
                avgWPM: response.data.avgWPM,
                maxErrRate: response.data.maxErrRate.result[0],
                maxWPM: response.data.maxWPM.result[0],
                minWPM: response.data.minWPM.result[0],
                contCode: response.data.contCode,
                globalWPM: response.data.globalWPM.result,
                backspaceData: backspaceData,
                avgErrRate: response.data.avgErrRate
            }
            
            console.log(response.data)
            return resultPageData
            // dispatch(setWPMHistogramData(resultPageData))
            // console.log(resultPageData, "resultpageDATA")
            // navigate(RESULTS);
            // console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return null
        }
    } catch (error) {
        console.error('Error fetching string array:', error);
        return null
    }


}