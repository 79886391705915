import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';
import api from '../../shared/apiSetup'

const Histogram = () => {
  const [histogramData, setHistogramData] = useState({});
  const isMobile = useSelector(state => state.home.isMobileDev);
  const uid = useSelector(state => state.user.uid);

  useEffect(() => {
    async function fetchHistogram() {
      const deviceType = isMobile ? 'mobile' : 'desktop'
      try {
        const response = await api.get(`/histogram?uid=${uid}&device_type=${deviceType}`); // Assuming FastAPI server is running on the same host
        // const xTicks = Array.from({ length: Math.ceil(response.data.maxBin / 5) + 1 }, (_, index) => index * 5);
        setHistogramData({
          data: response.data,
          maxBin: response.data.maxBin,
          highlightedBinIndex: response.data.highlightedBinIndex
          // xTicks: xTicks,
          // yTicks: yTicks
        })
      } catch (error) {
        console.error('Error fetching histogram:', error);
      }
    }

    fetchHistogram();
  }, []);

  return (
    <div  style={{ width: '100%' }}>
      {/* <h2>Normalized Histogram</h2> */}
      {histogramData.data && (
        <Plot
        style={{ width: '100%' }}
          data={[
            {
              type: 'bar',
              x: histogramData.data.bin_centers,
              y: histogramData.data.hist,
              marker: {
                color: histogramData.data.bin_centers.map((_, index) => {
                  // Highlight the bin containing the specified value
                  return index === histogramData.highlightedBinIndex ? '#008080' : '#FAD5A5';
                })
              },
              hoverinfo: 'none',
              // text: histogramData.data.hist.map(binHeight => binHeight < 0.5 ? '' : parseInt(binHeight)), // Display x values on top of bars if bin height is greater than or equal to 1
              text: histogramData.data.hist.map((binHeight, index) => binHeight < 0.5 ? '' : parseInt(histogramData.data.bin_centers[index])), // Display x values on top of bars if bin height is greater than or equal to 1
              // text: histogramData.data.bin_centers.map(value => parseInt(value)), // Display x values on top of bars,
              textposition: 'outside'
            }
          ]}
          layout={{
            // width: isMobile ? 500 : 900,
            // height: isMobile ? 200 : 600,
            autosize: true,
            margin: {
              l: 40,
              r: 20,
              b: 80,
              t: 20,
              pad: 0
            },
            // title: 'Normalized Histogram',
            xaxis: {
              dtick: isMobile ? 10: 5,
              range: [0, histogramData.maxBin],
              title: 'Value',
              // tickvals: histogramData.xTicks,
              // ticktext: histogramData.xTicks.map(val => val.toString()),
              tickangle: 0,
              ticklen: 5,
              tickfont: {
                size: 8    // font-size
              }
            },
            yaxis: {
              title: 'Percentage (%)', tickfont: {
                size: 10
              }
            },
          }}
        />
      )}
    </div>
  );
};

export default Histogram;
